import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledServicesList,
  StyledServicesListItem,
  StyledService,
  StyledServiceImg,
  StyledServiceGradientGradient,
  StyledServiceCategory,
} from './styles'
import { Typo3 } from '../Typography/Typography'
import { fluidPropType } from '../../constants/proptypes'
import Image from '../Image/Image'

const Services = ({ categories }) => (
  <StyledServicesList>
    {categories.map(({ category, image, url }, index) => (
      <StyledServicesListItem key={index}>
        <StyledService to={url}>
          <StyledServiceGradientGradient />
          <StyledServiceImg>
            <Image fluid={image} />
          </StyledServiceImg>
          <StyledServiceCategory>
            <Typo3 as="h3">{category}</Typo3>
          </StyledServiceCategory>
        </StyledService>
      </StyledServicesListItem>
    ))}
  </StyledServicesList>
)

export default Services

Services.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string.isRequired,
      image: fluidPropType,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
