import styled from 'styled-components'
import Link from 'gatsby-link'

import media from '../../common/MediaQueries'
import { transition } from '../../common/mixins'

const StyledServicesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  @media ${media.phone} {
    margin: -1.875rem -0.9375rem 0 -0.9375rem;
    width: calc(100% + 1.875rem);
  }
`

const StyledServicesListItem = styled.li`
  width: 100%;
  overflow: hidden;
  &:not(:last-child) {
    margin-bottom: 1.875rem;
  }

  @media ${media.phone} {
    width: calc(50% - 1.875rem);
    margin: 1.875rem 0.9375rem 0 0.9375rem;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  @media ${media.tablet} {
    width: calc(33.3% - 1.875rem);
  }
`

const StyledServiceImg = styled.div`
  ${transition}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledService = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 127.92%;
  background: ${({ theme }) => theme.colors.black};

  &:hover {
    ${StyledServiceImg} {
      opacity: 0.75;
      transform: scale3d(1.03, 1.03, 1.03);
    }
  }
`

const StyledServiceGradientGradient = styled.span`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    transparent 50%,
    ${({ theme }) => theme.colors.black}
  );
  ${transition({ property: 'opacity', duration: '350ms' })}

    @media ${media.tablet} {
        background: linear-gradient(
        180deg,
        transparent 70%,
        ${({ theme }) => theme.colors.black}
    );
  }
`

const StyledServiceCategory = styled.div`
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2.1875rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`

export {
  StyledServicesList,
  StyledServicesListItem,
  StyledServiceImg,
  StyledService,
  StyledServiceGradientGradient,
  StyledServiceCategory,
}
