import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Inner from '../components/Inner'
import Services from '../components/Services/Services'
import { Heading2, Heading5 } from '../components/Typography/Typography'
import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import InfoBox from '../components/InfoBox/InfoBox'
import { TRUNCATED_HERO_TYPES } from '../constants/truncatedHeroTypes'
import { BG_TYPES } from '../constants/bgTypes'
import { URL_PATHS } from '../constants/urlPaths'
import { replaceAtToSpan, generateHero } from '../common/utils'
import FlexContentBlocks from '../common/queries/FlexContentBlocks'
import HTMLBody from '../components/HTMLBody'

const ServicesPage = ({ data }) => {
  const { edges: categories } = data.wp.categories
  const page = data.wp.page?.servicesAcf
  const seoData = data.wp.page?.seo

  const contentBlocks = page.contentBlocks.content

  const {
    slantDirection,
    bgGradient,
    hasBgGradient,
    isHeroHigh,
    heroBgColorMobile,
    heroBgColorDesktop,
    heroColorMobile,
    heroColorDesktop,
    infoBoxOpacity,
    infoBoxBg,
    heroImages,
  } = generateHero(page)

  const filteredCategories = categories.filter(
    (category) => category.node.categoryOptions.isService,
  )

  const services = filteredCategories.map(({ node: { slug, name, acf } }) => {
    const categoryImage =
      acf.featuredImage !== null
        ? acf.featuredImage?.imageFile?.childImageSharp.fluid
        : acf.heroFields?.backgroundDesktop?.imageFile?.childImageSharp.fluid

    return {
      category: name,
      url: `${URL_PATHS.CATEGORY}/${slug}`,
      image: categoryImage,
    }
  })

  return (
    <Layout>
      <SEO title={seoData?.title} data={seoData} />
      <Container
        bg={[heroBgColorMobile, '', heroBgColorDesktop]}
        color={[heroColorMobile, '', heroColorDesktop]}
      >
        <TruncatedHero
          heroType={TRUNCATED_HERO_TYPES.withGradientOnMobile}
          heroSlantType={slantDirection || 'right'}
          opacityImg={0.9}
          gradient={hasBgGradient ? bgGradient : null}
          long={isHeroHigh}
          {...{ heroImages }}
        >
          <Container mt={['-4rem', '', '0']} mb={['', '2rem']}>
            <InfoBox
              title={
                <Heading2
                  as="h1"
                  dangerouslySetInnerHTML={{
                    __html: replaceAtToSpan(page.heroFields.infoboxTitle),
                  }}
                />
              }
              content={
                <HTMLBody opacity="1" html={page.heroFields.infoboxContent} />
              }
              bgOpacity={infoBoxOpacity}
              bg={infoBoxBg}
            />
          </Container>
        </TruncatedHero>
      </Container>

      <FlexContentBlocks pageData={data} {...{ contentBlocks }} />

      <Container
        pt={['2.5rem', '', '6.25rem']}
        pb={['2.5rem', '', '6.25rem']}
        bg={BG_TYPES.black}
      >
        <Inner>
          <Container mb={['2.5rem', '', '3.375rem']}>
            <Heading5
              as="h2"
              color="yellow"
              textAlign="center"
              className="uiAnimBottom"
            >
              VIP Escort Services
            </Heading5>
          </Container>
          <Services categories={services} />
        </Inner>
      </Container>
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
  query {
    wp {
      categories(first: 100) {
        edges {
          node {
            id
            name
            slug
            categoryOptions {
              isService
            }
            acf {
              featuredImage {
                mediaItemUrl
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 680) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              heroFields {
                backgroundDesktop {
                  mediaItemUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 960) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      page(id: "services", idType: URI) {
        seo {
          ...seoFragment
        }
        servicesAcf {
          heroFields {
            infoboxTitle
            infoboxContent
            slantDirection
            bgGradient
            boxBackgroundOpacity
            boxBackgroundColor
            heroHeight
            bgDesktop
            bgMobile
            backgroundDesktop {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundMobile {
              mediaItemUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 375) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          contentBlocks {
            content {
              ... on wp_Page_Servicesacf_ContentBlocks_Content_PictureAndContentBox {
                ...services_pictureBoxFragment
              }
            }
          }
        }
      }
    }
  }
`
